import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Github from '../components/GitHub';
 
const Home = () => {

    const mobile = window.innerWidth < 900 ? true : false
    return (
        <div>
            <Header mobile={mobile}/>
            <Github mobile={mobile}/>
            <Footer mobile={mobile}/>
        </div>
    )
}

export default Home