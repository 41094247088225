import React from 'react';
import GitHubCalendar from 'react-github-calendar'

function Github(props) {
    
    return (
        <div class='github'>
            <p>My recent <a href='https://github.com/keaneDonal'>GitHub</a> commits</p>
            <div class='github-calendar'><GitHubCalendar username='keaneDonal' hideColorLegend/></div>
        </div>
    )
}

export default Github