import React, { Component } from 'react';
import title from '../resources/images/title.png'
import donal from '../resources/images/donal-funky.png'
 
const Header = (props) => {
    
    return (
        <div className = 'header'>
            <img className = 'title-img' src={title}/>
            <img className = 'home-img' src={donal}/>
        </div>
    )
}

export default Header