import React, { Component } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
import github from '../resources/images/github-white.png'
import linkedin from '../resources/images/linkedin-white.png'
import email from '../resources/images/email.png'
 
const Footer = (props) => {

    function acceptedCookie() {
        localStorage.setItem('cookieConsent', true)
        ReactGA.initialize('UA-172495166-01');
        ReactGA.pageview(window.location.pathname + window.location.search);
        Cookies.set('_cid', localStorage.getItem('_cid'))
    }

    const socialIconStyle = props.mobile ? {maxWidth: '5%', margin: '5%'} : {maxWidth: '2%', margin: '2%'}


    return (
        <footer className = 'footer'>
            <a href='https://github.com/keaneDonal'><img className = 'social-icon' style={socialIconStyle} src={github}/></a>
            <a href='https://www.linkedin.com/in/donalocathain/'><img className = 'social-icon' style={socialIconStyle} src={linkedin}/></a>
            <a href='mailto:hi@donal.me'><img className = 'social-icon' style={socialIconStyle} src={email}/></a>
            {/* <CookieConsent containerClasses='consent-container' cookieName='_cid' cookieValue={uuidv4().concat('.', Date.now())} onAccept={this.acceptedCookie}>This website uses cookies to enhance the user experience.</CookieConsent> */}
        </footer>
    )
}

export default Footer